










































































































































import { Bar as BarChart } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  TimeScale,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'

import { getElasticSearchClient } from '@/services/elasticSearch'
import { formatPrice, addCommas, formatDateLabel } from '@/lib/utils'
import { CurrencyEnum } from '../../../shared/types/types'
import DatePickerMixin from '../mixins/DatePicker.mixin'
import { IntervalEnum } from '../lib/reports/types'
import Metric from '../components/reports/Metric.vue'

ChartJS.register(
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
)

export default DatePickerMixin.extend({
  name: 'Dashboard',
  components: {
    BarChart,
    Metric,
  },
  data() {
    return {
      timeInterval: IntervalEnum.Day,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        minBarLength: 5,
        scales: {
          y: {
            ticks: {
              stepSize: 100,
              callback: (value: any) =>
                formatPrice({
                  amount: value * 100,
                  currency: CurrencyEnum.Usd,
                }),
            },
          },
        },
      },
      ordersData: [] as Record<string, any>,
      productsData: [] as Record<string, any>,
      unfulfilledOrdersData: [] as Record<string, any>,
      highRiskOrdersData: [] as Record<string, any>,
      chargebacksData: [] as Record<string, any>,
    }
  },
  computed: {
    ordersQuery(): Record<string, any> {
      return {
        aggs: {
          totalRevenue: {
            sum: {
              field: 'usdPrices.totalPrice.amount',
            },
          },
          totalRefund: {
            sum: {
              field: 'usdPrices.totalRefund.amount',
            },
          },
          totalChargebackFee: {
            sum: {
              field: 'usdPrices.totalChargebackFee.amount',
            },
          },
          totalFee: {
            sum: {
              field: 'usdPrices.fee.amount',
            },
          },
          revenueOverTime: {
            date_histogram: {
              field: '@timestamp',
              calendar_interval: this.timeInterval,
              offset: '+4h',
            },
            aggs: {
              totalPrice: {
                sum: {
                  field: 'usdPrices.totalPrice.amount',
                },
              },
              totalRefund: {
                sum: {
                  field: 'usdPrices.totalRefund.amount',
                },
              },
            },
          },
          unfulfilledOrders: {
            filter: {
              match_phrase: {
                fulfillmentStatus: 'unfulfilled',
              },
            },
          },
          highRisk: {
            filter: {
              bool: {
                must: [],
                filter: [
                  {
                    bool: {
                      should: [
                        {
                          bool: {
                            should: [
                              {
                                match_phrase: {
                                  'charge.risk.level': 'elevated',
                                },
                              },
                            ],
                            minimum_should_match: 1,
                          },
                        },
                        {
                          bool: {
                            should: [
                              {
                                match_phrase: {
                                  'charge.risk.level': 'highest',
                                },
                              },
                            ],
                            minimum_should_match: 1,
                          },
                        },
                      ],
                      minimum_should_match: 1,
                    },
                  },
                ],
                should: [],
                must_not: [],
              },
            },
          },
          disputes: {
            filter: {
              exists: {
                field: 'dispute',
              },
            },
          },
        },
        track_total_hits: true,
        size: 0,
        query: {
          bool: {
            must: [
              {
                bool: {
                  minimum_should_match: 1,
                  should: [
                    {
                      terms: {
                        paymentStatus: ['paid'],
                      },
                    },
                    {
                      terms: {
                        paymentStatus: ['partially_refunded'],
                      },
                    },
                    {
                      terms: {
                        paymentStatus: ['refunded'],
                      },
                    },
                  ],
                  must_not: [],
                },
              },
            ],
            filter: [
              {
                range: {
                  '@timestamp': {
                    format: 'strict_date_optional_time',
                    gte: this.datePicker.startDate,
                    lte: this.datePicker.endDate,
                  },
                },
              },
            ],
          },
        },
      }
    },
    productsQuery(): Record<string, any> {
      return {
        aggs: {
          products: {
            terms: {
              field: 'productTitle.keyword',
              order: {
                quantity: 'desc',
              },
              size: 5,
            },
            aggs: {
              quantity: {
                sum: {
                  field: 'quantity',
                },
              },
              totalPrice: {
                sum: {
                  field: 'usdPrices.totalPrice.amount',
                },
              },
            },
          },
        },
        size: 0,
        query: {
          range: {
            '@timestamp': {
              time_zone: '-05:00',
              format: 'strict_date_optional_time',
              gte: this.datePicker.startDate,
              lte: this.datePicker.endDate,
            },
          },
        },
      }
    },
    unfulfilledOrdersQuery(): Record<string, any> {
      return {
        track_total_hits: true,
        size: 0,
        _source: false,
        query: {
          term: {
            fulfillmentStatus: 'unfulfilled',
          },
        },
      }
    },
    highRiskOrdersQuery(): Record<string, any> {
      return {
        track_total_hits: true,
        size: 0,
        _source: false,
        query: {
          terms: {
            'charge.risk.level': ['elevated', 'highest'],
          },
        },
      }
    },
    chargebacksQuery(): Record<string, any> {
      return {
        track_total_hits: true,
        size: 0,
        _source: false,
        query: {
          terms: {
            'dispute.status': [
              'needs_response',
              'warning_needs_response',
              'under_review',
              'warning_under_review',
            ],
          },
        },
      }
    },
    numberOfOrders(): string {
      return addCommas(this.ordersData?.hits?.total?.value || 0)
    },
    disputes(): string {
      return addCommas(this.ordersData?.aggregations?.disputes.doc_count || 0)
    },
    highRisk(): string {
      return addCommas(this.ordersData?.aggregations?.highRisk.doc_count || 0)
    },
    unfulfilledOrders(): string {
      return addCommas(
        this.ordersData?.aggregations?.unfulfilledOrders.doc_count || 0
      )
    },
    totalRefund(): string {
      return formatPrice({
        amount: this.ordersData?.aggregations?.totalRefund.value || 0,
        currency: CurrencyEnum.Usd,
      })
    },
    totalSalesAmount(): number {
      const totalRevenue =
        this.ordersData?.aggregations?.totalRevenue.value || 0
      const totalRefunds = this.ordersData?.aggregations?.totalRefund.value || 0
      return totalRevenue - totalRefunds
    },
    totalSales(): string {
      return formatPrice({
        amount: this.totalSalesAmount,
        currency: CurrencyEnum.Usd,
      })
    },
    totalRevenue(): string {
      return formatPrice({
        amount: this.ordersData?.aggregations?.totalRevenue.value || 0,
        currency: CurrencyEnum.Usd,
      })
    },
    totalFeeAmount(): number {
      return this.ordersData?.aggregations?.totalFee.value || 0
    },
    totalFee(): string {
      return formatPrice({
        amount: this.totalFeeAmount,
        currency: CurrencyEnum.Usd,
      })
    },
    totalChargebackFeeAmount(): number {
      return this.ordersData?.aggregations?.totalChargebackFee.value || 0
    },
    totalChargebackFee(): string {
      return formatPrice({
        amount: this.totalChargebackFeeAmount,
        currency: CurrencyEnum.Usd,
      })
    },
    whatsLeft(): string {
      const whatsLeft =
        this.totalSalesAmount -
        this.totalFeeAmount -
        this.totalChargebackFeeAmount
      return formatPrice({
        amount: whatsLeft,
        currency: CurrencyEnum.Usd,
      })
    },
    revenueBuckets(): {
      doc_count: number
      key: number
      totalPrice: { value: number }
      totalRefund: { value: number }
    }[] {
      return this.ordersData?.aggregations?.revenueOverTime.buckets || []
    },
    productColumns(): { field: string; label: string; numeric?: true }[] {
      return [
        {
          field: 'product',
          label: 'Product',
        },
        {
          field: 'quantity',
          label: 'Quantity',
          numeric: true,
        },
        {
          field: 'revenue',
          label: '$ Revenue',
          numeric: true,
        },
      ]
    },
    chartData(): {
      labels: string[]
      datasets: {
        label: string
        data: number[]
        borderColor: string
        backgroundColor: string
        fill: boolean
      }[]
    } {
      return {
        labels: this.revenueBuckets.map((bucket, _, buckets) =>
          formatDateLabel(
            buckets.map((b) => new Date(b.key)),
            new Date(bucket.key),
            this.timeInterval
          )
        ),
        datasets: [
          {
            label: '$ Revenue',
            data: this.revenueBuckets.map(
              (bucket) => bucket.totalPrice.value / 100
            ),
            borderColor: '#4c51bf',
            backgroundColor: '#4c51bf',
            fill: false,
          },
        ],
      }
    },
    productsTableData(): {
      product: string
      quantity: string
      revenue: string
    }[] {
      return this.productsData?.aggregations?.products.buckets.map(
        (bucket: {
          key: string
          quantity: { value: number }
          totalPrice: { value: number }
        }) => ({
          product: bucket.key,
          quantity: addCommas(bucket.quantity.value),
          revenue: formatPrice({
            amount: bucket.totalPrice.value,
            currency: CurrencyEnum.Usd,
          }),
        })
      )
    },
    unfulfilledOrdersCount(): number {
      return this.unfulfilledOrdersData?.hits?.total?.value || 0
    },
    highRiskOrdersCount(): number {
      return this.highRiskOrdersData?.hits?.total?.value || 0
    },
    chargebacksCount(): number {
      return this.chargebacksData?.hits?.total?.value || 0
    },
  },
  methods: {
    onRangeChanged({
      startDate,
      endDate,
    }: {
      startDate: Date
      endDate: Date
    }): Promise<void> {
      this.datePicker.startDate = startDate
      this.datePicker.endDate = endDate
      return this.fetchData()
    },
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const [
        { data: ordersData },
        { data: productsData },
        { data: unfulfilledOrdersData },
        { data: highRiskOrdersData },
        { data: chargebacksData },
      ] = await Promise.all([
        elasticSearch.post('/orders/_search', this.ordersQuery),
        elasticSearch.post('/order_line_items/_search', this.productsQuery),
        elasticSearch.post('/orders/_search', this.unfulfilledOrdersQuery),
        elasticSearch.post('/orders/_search', this.highRiskOrdersQuery),
        elasticSearch.post('/orders/_search', this.chargebacksQuery),
      ])
      this.ordersData = ordersData
      this.productsData = productsData
      this.unfulfilledOrdersData = unfulfilledOrdersData
      this.highRiskOrdersData = highRiskOrdersData
      this.chargebacksData = chargebacksData
    },
  },
  created() {
    this.fetchData()
  },
})
